import React, { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../../assets/soundSuitIcon/logoSS@1x.png";
import { newPassword as newPasswordService } from "../../services/SoundSuitService";

const ResetPassword = () => {
  const { token, email } = useParams<{ token: string, email: string }>();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    setError(null);

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const resultReset = await newPasswordService({ 
        password: newPassword, 
        password_confirmation: confirmPassword, 
        token 
      });

      if (resultReset === "") {
        navigate("/success-password", { state: { email } });
      } else {
        setError(resultReset);
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>Reset your password</Title>
      <Description>
        Enter the new password you want to use with your Soundsuit account{" "}
        <UserEmail>{email}</UserEmail>.
      </Description>
      <Form>
        <TitleForm align="center">New Password</TitleForm>
        <Input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
        />
        <TitleForm align="center">Confirm Password</TitleForm>
        <Input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Re-Enter New Password"
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <SaveButton 
          onClick={handleSave} 
          disabled={newPassword.length === 0 || confirmPassword.length === 0}
        >
          Save
        </SaveButton>
      </Form>
      <BackToLogin onClick={() => navigate("/signin")}>
        &lt; Back to login
      </BackToLogin>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 50px;
`;

const Description = styled.p`
  font-size: 14px;
  text-align: center;
  color: #555555;
  margin-bottom: 30px;
`;

const UserEmail = styled.span`
  color: #007aff;
`;

const Form = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-size: 14px;
`;

const TitleForm = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 4px;
`;

const SaveButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#FF6259')};
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: 10px;
  margin-bottom: 25px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#EE6259')};
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-bottom: 10px;
`;

const BackToLogin = styled.div`
  font-size: 14px;
  color: #ff6b6b;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 20px;

  &:hover {
    color: #e55e5e;
  }
`;

export default ResetPassword;
