import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/soundSuitIcon/logoSS@1x.png";
import { green, white } from '../../config/colors';
import { CheckOutlined } from '@ant-design/icons';

interface Props {
  email: string;
}

const SuccessPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as Props | undefined;
  const email = state?.email || "";

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>Success</Title>
      <Square>
        <CheckOutlined style={{ fontSize: '38px', margin: 'auto', color: white }} />
      </Square>
      <Description>
        You just saved a new password for your Soundsuit account <UserEmail>{email}</UserEmail>.
      </Description>
      <Description>
        Now you can go back to your Soundsuit app and log in with the new password.
      </Description>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
`;

const Square = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${green};
  border-radius: 25px;
  display: flex;
  margin-bottom: 50px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
  border-radius: 50%;
`;

const UserEmail = styled.span`
  color: #007aff;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-size: 14px;
  text-align: center;
  color: #555555;
  margin-bottom: 30px;
`;

const BackToLogin = styled.div`
  font-size: 14px;
  color: #ff6b6b;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #e55e5e;
  }
`;

export default SuccessPassword;
